import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modifier from "draft-js/lib/DraftModifier";
import EditorState from "draft-js/lib/EditorState";

import config from "../../config/main.config";
import {getDefaultCMSItem} from "../../helper/util";
import {textUpdate} from "../../helper/TextTranslatorUtil";

export {
    getAllImages,
    getFilteredImages,
    handleSave
}

function getAllImages(setAllImages) {
    fetch(config.BASE_URL + "images/web", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                let allImages = [];
                for (let i = 0; i < json.length; i++) {
                    allImages.push({
                        link: json[i],
                        isActive: false,
                    })
                }
                setAllImages(allImages);
            });
        } else {
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                window.history.replace("/admin-login");
            }
        }
    );
}

function getFilteredImages(allImages, imageFilter, imagePath, value, editor, setImageModal, setSelectedImage) {
    let filteredImages = [];
    for (let i = 0; i < allImages.length; i++) {
        let item = allImages[i];
        if (imageFilter.length === 0 || item.link.toLowerCase().includes(imageFilter.toLowerCase())) {
            filteredImages.push(
                <Grid.Column key={i} width={5}>
                    <img
                        role={"button"}
                        className={item.isActive ? "image-active" : "image-inactive"}
                        src={config.BASE_URL_IMAGES + imagePath + item.link}
                        alt={''}
                        onClick={() => {
                            let src = config.BASE_URL_IMAGES + imagePath + item.link;
                            let editorState = value.getEditorState();
                            let contentState = editorState.getCurrentContent();
                            let selection = editorState.getSelection();
                            contentState = contentState.createEntity("image", 'IMMUTABLE', {src});
                            let entityKey = contentState.getLastCreatedEntityKey();
                            if (!item.isActive) {
                                let newContentState = Modifier.insertText(contentState, selection, ' ', null, entityKey);
                                editor.current._onChange(EditorState.push(editorState, newContentState, "insert-fragment"));
                                setSelectedImage(1);
                            }
                            item.isActive = true;
                            setImageModal(false);
                        }}
                    />
                </Grid.Column>
            );
        }
        item.isActive = false;
    }
    return filteredImages;
}

function handleSave(textToShow, namespace, textStore, id, language, setIsEditState, onClose){
    let textToEdit = {
        ...getDefaultCMSItem(),
        content: textToShow.content,
        isNamespace: namespace
    };
    textUpdate(textStore, textToEdit, id, language.languageCode, namespace);
    setIsEditState(false);
    onClose(textToEdit);
}