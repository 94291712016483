import config from "../config/main.config";
import {getTokenFromLocalStorage, updateText} from "./util";

export function getTranslatedText(target_lang, source_lang, text) {
    let rawText = new DOMParser().parseFromString(text, "text/html").documentElement.textContent;
    return new Promise(result => fetch(config.BASE_URL + "texts/translate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
            "language": source_lang,
            "content": rawText,
            "target_lang": target_lang
        })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                result(json)
            })
        }
    }).catch(error => {
        console.error(error)
        throw error;
    }));
}

export async function textUpdate(textStore, text, id, language, ns) {
    await updateText(id, text, language, ns).then(bool => {
        return bool;
    }).catch((error) => {
        console.error("error: ", error);
    });
    return true
}

export function getStyle(style) {
    if (style === null) {
        style = {
            "zIndex": 1,
            "position": "relative",
            "backgroundColor": "white",
            "overflow": "auto"
        };
        return style;
    } else {
        // need to copy this because otherwise I get "not extendable error"
        let customStyle = Object.assign({}, style);
        customStyle.zIndex = 1;
        customStyle.position = "relative";
        customStyle.backgroundColor = "white";
        customStyle.overflow = "auto";
        style = customStyle;
        return style;
    }
}