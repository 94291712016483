import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import RichTextEditor, {getTextAlignClassName, getTextAlignStyles} from 'react-rte';
import {Button, Modal} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {withTranslation} from "react-i18next";

import {getResponsiveImagePath} from "../../helper/util";
import * as utils from "./utils";

const Editor = (props) => {
    const editor = React.createRef();
    const [imageModal, setImageModal] = useState(false);
    const [imageFilter, setImageFilter] = useState("");
    const [allImages, setAllImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [imagePath] = useState(getResponsiveImagePath(window.outerWidth));
    const [value, setValue] = useState(RichTextEditor.createValueFromString(props.text, props.format));
    const {i18n} = props;

    useEffect(() => {
        if (selectedImage === 1) {
            setValue(RichTextEditor.createValueFromString(props.text, "html"));
        }
        setSelectedImage(0);
    }, [selectedImage]);

    useEffect(() => {

        utils.getAllImages(setAllImages);
    }, [])

    return (
        <RichTextEditor
            autoFocus={true}
            ref={editor}
            value={value}
            onChange={(value) => {
                if (value.toString(props.format, {blockStyleFn: getTextAlignStyles}).length <= 65535) {
                    setValue(value);
                    props.updateText(value.toString(props.format, {blockStyleFn: getTextAlignStyles}));
                }
            }}
            placeholder="Tell a story (max 65'000 characters)"
            toolbarConfig={RichTextEditor.toolbarConfig}
            blockStyleFn={getTextAlignClassName}
            customControls={[
                <Button className={"admin-button-primary"}
                        icon={"image"}
                        style={{marginLeft: "5px"}}
                        size={"mini"}
                        onClick={() => {
                            utils.getAllImages(setAllImages);
                            setImageModal(true);
                        }}/>,
                <Modal className={"admin-primary"} open={imageModal}>
                    <Modal.Header className={"admin-primary"}>Select Image</Modal.Header>
                    <Modal.Content className={"admin-primary"} scrolling>
                        <label className={"admin-primary"}>
                            <b>{i18n.t("common:common.search")}</b>
                        </label>
                        <br/>
                        <input className={"admin-primary"}
                               value={imageFilter}
                               onChange={(e) => {
                                   setImageFilter(e.target.value);
                               }}/>
                        <Grid>
                            <Grid.Row>
                                {utils.getFilteredImages(allImages, imageFilter, imagePath, value, editor, setImageModal, setSelectedImage)}
                            </Grid.Row>
                        </Grid>
                        <Modal.Actions>
                            <Button className={"admin-button-primary"}
                                    icon={"checkmark"}
                                    floated={"left"}
                                    onClick={() => {
                                        setValue(RichTextEditor.createValueFromString(props.text, "html"));
                                        setImageModal(false);
                                    }}/>
                            <Button className={"admin-button-warn"}
                                    floated={"right"}
                                    onClick={() => setImageModal(false)}
                                    icon={"delete"}/>
                        </Modal.Actions>
                    </Modal.Content>
                </Modal>
            ]}
        />
    )
}

Editor.propTypes =
    {
        text: PropTypes.string,
        format: PropTypes.string,
        updateText: PropTypes.func,
    };
Editor.defaultProps = {
    text: "",
    format: "",
    updateText: () => {
    }
};

export default withTranslation(['common'])(Editor)